<template>
  <div class="v_task">
    <!-- 头部 -->
    <head-back :isBack="false">
      <template>
        <span>任务列表</span>
      </template>
    </head-back>
    <div class="v-task-list">
      <!-- 每一个任务类型 垫付任务-->
      <div class="v-task-item">
        <p class="v-task-item-title">垫付任务</p>
        <div class="v-task-item-sorts-list g-flex-align-center">
          <div class="v-task-sorts-item g-flex-column g-flex-align-center" @click="taskundneClick('0')">
            <i class="iconfont icon-weiwancheng"></i>
            <div class="v-task-status g-flex-align-center">
              <span>未完成</span>
              <label v-show="totalForm.df.undown">+{{ totalForm.df.undown }}</label>
            </div>
          </div>

          <div class="v-task-sorts-item  g-flex-column g-flex-align-center"  @click="taskalreadyClick('0')">
            <i class="iconfont icon-xuanze"></i>
            <div class="v-task-status">
              <span>已完成</span>
              <label v-show="totalForm.df.already">+{{ totalForm.df.already }}</label>
            </div>
          </div>

          <div class="v-task-sorts-item  g-flex-column g-flex-align-center" @click="taskrevokedClick('0')">
            <i class="iconfont icon-yiquxiao"></i>
            <div class="v-task-status">
              <span>已撤销</span>
            </div>
          </div>

        </div>
      </div>

      <!-- 每一个任务类型  浏览任务-->
      <div class="v-task-item">
        <p class="v-task-item-title">浏览任务</p>
        <div class="v-task-item-sorts-list g-flex-align-center">
          <div class="v-task-sorts-item g-flex-column g-flex-align-center" @click="taskundneClick('1')">
            <i class="iconfont icon-weiwancheng"></i>
            <div class="v-task-status g-flex-align-center">
              <span>未完成</span>
              <label v-show="totalForm.ll.undown">+{{ totalForm.ll.undown }}</label>
            </div>
          </div>

          <div class="v-task-sorts-item  g-flex-column g-flex-align-center" @click="taskalreadyClick('1')">
            <i class="iconfont icon-xuanze"></i>
            <div class="v-task-status">
              <span>已完成</span>
              <label v-show="totalForm.ll.already">+{{ totalForm.ll.already }}</label>
            </div>
          </div>

          <div class="v-task-sorts-item  g-flex-column g-flex-align-center" @click="taskrevokedClick('1')">
            <i class="iconfont icon-yiquxiao"></i>
            <div class="v-task-status">
              <span>已撤销</span>
            </div>
          </div>

        </div>
      </div>

      <!-- 每一个任务类型 预售任务-->
      <div class="v-task-item">
        <p class="v-task-item-title">预售任务</p>
        <div class="v-task-item-sorts-list g-flex-align-center">
          <div class="v-task-sorts-item g-flex-column g-flex-align-center" @click="taskundneClick('2')">
            <i class="iconfont icon-weiwancheng"></i>
            <div class="v-task-status g-flex-align-center">
              <span>未完成</span>
              <label v-show="totalForm.ys.undown">+{{ totalForm.ys.undown }}</label>
            </div>
          </div>

          <div class="v-task-sorts-item  g-flex-column g-flex-align-center" @click="taskalreadyClick('2')">
            <i class="iconfont icon-xuanze"></i>
            <div class="v-task-status">
              <span>已完成</span>
              <label v-show="totalForm.ys.already">+{{ totalForm.ys.already }}</label>
            </div>
          </div>

          <div class="v-task-sorts-item  g-flex-column g-flex-align-center" @click="taskrevokedClick('2')">
            <i class="iconfont icon-yiquxiao"></i>
            <div class="v-task-status">
              <span>已撤销</span>
            </div>
          </div>

        </div>
      </div>
    </div>
    
    <div class="v-task-tips">
      请在物流到后第二天及时确认收货及评价，发货之后15天还未收货的系统会自动确认收货并且扣除20%的佣金
    </div>
  </div>
</template>

<script>
  import HeadBack from '../../components/HeadBack.vue'
  import { apiGetTotalOrder } from '@/utils/api.js'
  export default {
    components: { HeadBack },
    data() {
      return {
        form: {
          df: { s10: 0, s20: 0, s30: 0, s40: 0, s50: 0, s60: 0, s70: 0 },
          ll: { s10: 0, s20: 0, s30: 0, s40: 0, s50: 0, s60: 0, s70: 0 },
          ys: { s10: 0, s20: 0, s30: 0, s40: 0, s50: 0, s60: 0, s70: 0 },
        }
      }
    },
    created() {
      this.apiGetTotalOrderHandel()
    },
    computed: {
      totalForm() {
        return {
          df: {
            undown: this.form.df.s10 + this.form.df.s20 + this.form.df.s30 + this.form.df.s40 + this.form.df.s50,
            already: this.form.df.s60 + this.form.df.s70
          },
          ll: {
            undown: this.form.ll.s10 + this.form.ll.s20 + this.form.ll.s30 + this.form.ll.s40 + this.form.ll.s50,
            already: this.form.ll.s60 + this.form.ll.s70 
          },
          ys: {
            undown: this.form.ys.s10 + this.form.ys.s20 + this.form.ys.s30 + this.form.ys.s40 + this.form.ys.s50,
            already: this.form.ys.s60 + this.form.ys.s70
          }
        }
      }
    },
    methods: {
      // 获取任务数量 s10=待操作  s20=待操作  s30=待操作 s40=待返款 s50=待收货 s60=待完成 s70已完成
      async apiGetTotalOrderHandel() {
        this.$global.loadingShow = true
        const { success, data } = await apiGetTotalOrder({ type: 'ALL' })
        if(!success) return
        this.form = data.data
        console.log(data)
      },
      // 已撤销点击 type = 0 垫付任务   type = 1 浏览任务  type = 2 预售任务 后期全部拆开了 原本是一个组件 一个路由
      taskrevokedClick(type) {
        if(type == 0) return this.$router.push({ name: 'taskdianfurevoked', params: { type }})
        if(type == 1) return this.$router.push({ name: 'taskliulanrevoked', params: { type }})
        if(type == 2) return this.$router.push({ name: 'taskyusourevoked', params: { type }})
      },
      // 已完成点击 type = 0 垫付任务   type = 1 浏览任务  type = 2 预售任务 后期全部拆开了 原本是一个组件 一个路由
      taskalreadyClick(type) {
        if(type == 0) return this.$router.push({ name: 'taskdianfualready', params: { type }})
        if(type == 1) return this.$router.push({ name: 'taskliulanalready', params: { type }})
        if(type == 2) return this.$router.push({ name: 'taskyusoualready', params: { type }})
      },

      // 未完成点击 type = 0 垫付任务   type = 1 浏览任务  type = 2 预售任务 后期全部拆开了 原本是一个组件 一个路由
      taskundneClick(type) {
        if(type == 0) return this.$router.push({ name: 'taskdianfuundone', params: { type }})
        if(type == 1) return this.$router.push({ name: 'taskliulanundone', params: { type }})
        if(type == 2) return this.$router.push({ name: 'taskyusouundone', params: { type }})
      },
    }
  }
</script>

<style lang="scss">
.v_task {
  width: 100%;
  height: 100%;
  background: #F1F1F1;
  .v-task-list {
    padding: 10px;
    .v-task-item {
      margin-top: 12px;
      background: $white;
      border-radius: 12px;
      padding: 12px 15px;
      .v-task-item-title {
        color: $black;
        font-size: 14px;
        font-weight: bold;
      }
      .v-task-item-sorts-list {
        padding-top: 14px;
        .v-task-sorts-item {
          flex: 1;
          .iconfont {
            color: $main_color;
            font-size: 36px;
          }
          .v-task-status {
            padding-top: 16px;
            span {
              font-size: 14px;
              color: #707070;
            }
            label {
              padding-left: 10px;
              color: $main_color;
              font-size: 16px;
            }
          }
        }
      }
    }
  }

  .v-task-tips {
    font-size: 14px;
    padding: 0 20px;
    margin-top: 10px;
    letter-spacing: 2px;
    color: $main_color;
    font-weight: 500;
    line-height: 22px;
  }
}
</style>